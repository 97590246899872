import Icon, { EIcon } from '../Icon';

export enum Device {
  DESKTOP = 'desktop',
  PHONE = 'phone',
}

interface PeerProps {
  onClick: () => void;
  type: Device;
  name: string;
}

const Peer: React.FC<PeerProps> = ({ onClick, type, name }) => {
  const iconMap = {
    [Device.DESKTOP]: EIcon.MONITOR,
    [Device.PHONE]: EIcon.PHONE,
  };

  const icon = iconMap[type] || EIcon.MONITOR;

  return (
    <div className="device" onClick={() => onClick()}>
      <div className="device-icon">
        <Icon icon={icon} size={36} strokeWidth={2} />
      </div>
      <div className="device-name">{name}</div>
    </div>
  );
};

export default Peer;
