import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import Icon, { EIcon } from './components/Icon';
import Signaling from './ws/signaling';
import RTCPeer from './webrtc/webrtc-peer';
import Events from './ws/events';
import Discover from './components/Discover';
import Header from './components/Header';
import Connected from './components/Connected';

export interface PeerData {
  rtcPeer: RTCPeer;
  metadata: any;
}

const App = () => {
  const [connectedPeers, setConnectedPeers] = useState<PeerData[]>([]);
  const [isConnected, setConnected] = useState(false);

  const [media, setMedia] = useState<{
    track: MediaStreamTrack;
    streams: MediaStream[];
    remotePeer: RTCPeerConnection;
  } | null>(null);

  const onMessage = useCallback(
    ({ detail: message }: any) => {
      const peer = connectedPeers.find(
        (peer) => peer.rtcPeer.peerId === message.sender
      );
      peer?.rtcPeer.onMessage(message);
    },
    [connectedPeers]
  );

  const onPeers = useCallback(
    ({ detail: { peers } }: any) => {
      console.log('new peers');
      const newPeers: PeerData[] = peers.map((peer: any) => {
        return {
          rtcPeer: new RTCPeer(Signaling, peer.id),
          metadata: peer,
        };
      });

      setConnectedPeers([...connectedPeers, ...newPeers]);
    },
    [connectedPeers]
  );

  const onPeerJoined = useCallback(
    ({ detail: { peer } }: any) => {
      const newPeer = {
        rtcPeer: new RTCPeer(Signaling, peer.id),
        metadata: peer,
      };

      setConnectedPeers([...connectedPeers, newPeer]);
    },
    [connectedPeers]
  );

  const onConnected = ({ detail: media }: CustomEvent) => {
    // We have received the remote track
    // we want to listen to, which means
    // we have successfully connected to
    // remote peer

    setMedia(media);
    setConnected(true);
  };

  const onPeerLeft = useCallback(
    ({ detail: { peerId } }: any) => {
      const peer = connectedPeers.find(
        (peer) => peer.rtcPeer.peerId === peerId
      );

      if (!peer) {
        return;
      }

      peer.rtcPeer.peer.close();
      setConnectedPeers(
        connectedPeers.filter((peer) => peer.rtcPeer.peerId !== peerId)
      );
    },
    [connectedPeers]
  );

  const mockConnect = () => {
    setConnected(true);
  };

  useEffect(() => {
    console.log('Attaching listeners.');
    Events.on('peers', onPeers);
    Events.on('peer-joined', onPeerJoined);
    Events.on('peer-left', onPeerLeft);
    Events.on('signal', onMessage);
    Events.on('track', onConnected);

    Signaling!.connect();

    return () => {
      Events.off('peer', onPeers);
      Events.off('peer-joined', onPeerJoined);
      Events.off('signal', onMessage);
      Events.off('peer-left', onPeerLeft);
      Events.off('track', onConnected);
    };
  }, [onPeers, onPeerJoined, onPeerLeft, onMessage]);

  return (
    <div className="app">
      <Header />
      {isConnected ? (
        <Connected {...media!} />
      ) : (
        <Discover
          onClick={() => mockConnect()}
          connectedPeers={connectedPeers}
        />
      )}
    </div>
  );
};

export default App;
