export default class Events {
  static fire(type: string, detail: any) {
    window.dispatchEvent(new CustomEvent(type, { detail: detail }));
  }

  static on(type: string, callback: any) {
    return window.addEventListener(type, callback, false);
  }

  static off(type: string, callback: any) {
    return window.removeEventListener(type, callback, false);
  }
}
