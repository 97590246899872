import React, { useEffect, useState } from 'react';
import { useWakeLock } from 'react-screen-wake-lock';
import RTCPeer from '../../webrtc/webrtc-peer';
import Icon, { EIcon } from '../Icon';
import Player from '../Player';
import Stats from '../Stats';

interface ConnectedProps {
  track: MediaStreamTrack;
  streams: MediaStream[];
  remotePeer: RTCPeerConnection;
}

const Connected: React.FC<ConnectedProps> = ({
  track,
  streams,
  remotePeer,
}) => {
  const [IP, setIP] = useState<string>();
  const { release, released, request } = useWakeLock();

  return (
    <React.Fragment>
      <main>
        <Stats onIP={(ip) => setIP(ip)} remotePeer={remotePeer}></Stats>
      </main>
      <div className="connected-indicator">
        <Icon icon={EIcon.RADIO} size={128} strokeWidth={0.5} />
        <div className="connected-text">
          <span>Connected to:</span>
          <u>Windows Chrome</u>
        </div>
        <div>IP: {IP}</div>
      </div>
      <div className="screen-lock">
        Turn on Screen Lock
        <button
          type="button"
          onClick={() => (released === false ? release() : request())}
        >
          {released === false ? 'Release' : 'Request'}
        </button>
      </div>
      <Player track={track} streams={streams} />
    </React.Fragment>
  );
};

export default Connected;
