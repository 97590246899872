import { useEffect, useRef, useState } from 'react';
import Icon, { EIcon } from '../Icon';

interface PlayerProps {
  track: MediaStreamTrack;
  streams: MediaStream[];
}

const Player: React.FC<PlayerProps> = ({ track, streams }) => {
  const [volume, setVolume] = useState<number>(0);
  const [icon, setIcon] = useState<EIcon>(EIcon.VOLUME2);
  const [isPlaying, setPlaying] = useState<boolean>(false);

  const audio = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (audio.current) {
      audio.current.srcObject = streams[0];
      // audio.current.src =
      //   'https://actions.google.com/sounds/v1/alarms/bugle_tune.ogg';
      audio.current.autoplay = false;
    }
  }, []);

  useEffect(() => {
    if (volume === 0) {
      setIcon(EIcon.VOLUME_X);
    } else if (volume > 70) {
      setIcon(EIcon.VOLUME2);
    } else if (volume > 30) {
      setIcon(EIcon.VOLUME1);
    } else {
      setIcon(EIcon.VOLUME);
    }

    if (audio.current) {
      audio.current.volume = volume / 100;
    }
  }, [volume]);

  const togglePlay = () => {
    if (audio.current) {
      console.log(audio.current.paused);
      if (!audio.current.paused) {
        setPlaying(false);
        return audio.current.pause();
      }

      if (audio.current.paused) {
        setPlaying(true);
        return audio.current.play();
      }
    }
  };

  return (
    <div className="player">
      <div className="volume-indicator">
        <Icon icon={icon} size={32} />
        <input
          type="range"
          className="slider"
          value={volume}
          onChange={(e) => setVolume(parseInt(e.target.value))}
        />
        <audio hidden controls ref={audio}></audio>
      </div>
      <div className="control" onClick={() => togglePlay()}>
        {isPlaying ? (
          <Icon icon={EIcon.PAUSE} size={32} />
        ) : (
          <Icon icon={EIcon.PLAY} size={32} />
        )}
      </div>
    </div>
  );
};

export default Player;
