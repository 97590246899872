import React, { useEffect } from 'react';
import { PeerData } from '../../App';
import Icon, { EIcon } from '../Icon';
import Peer, { Device } from './Peer';

interface DiscoverProps {
  connectedPeers: PeerData[];
  onClick: () => void; // dev only
}

const Discover: React.FC<DiscoverProps> = ({ connectedPeers, onClick }) => {
  const peers = connectedPeers.map((cPeer, i) => {
    const name = cPeer.metadata.name.os + ' ' + cPeer.metadata.name.deviceName;

    return (
      <Peer
        key={i}
        name={name}
        type={Device.DESKTOP}
        onClick={() => cPeer.rtcPeer.createOffer()}
      ></Peer>
    );
  });

  return (
    <React.Fragment>
      <main>
        <div className="main">
          <span>Click to listen to device</span>
        </div>
        <div className="devices">{peers}</div>
      </main>
      <div className="bottom">
        <div className="bottom-icon">
          <Icon icon={EIcon.WIFI} size={80} strokeWidth={1}></Icon>
        </div>
        <div className="search-text">Searching for devices ...</div>
        <div className="notify-text">
          You can be discovered by everyone on this network
        </div>
      </div>
    </React.Fragment>
  );
};

export default Discover;
