import { useEffect, useState } from 'react';
import { formatBytes } from '../../utils/formatBytes';
import RTCPeer from '../../webrtc/webrtc-peer';
import Icon, { EIcon } from '../Icon';

interface StatsProps {
  remotePeer: RTCPeerConnection;
  onIP: (ip: string) => void;
}

// The stats component will generate statistics about the amount
// of data transferred in the current session

// also how much time has the thing been active

const Stats: React.FC<StatsProps> = ({ remotePeer, onIP }) => {
  const [dataReceived, setDataReceived] = useState<number>(0);

  const getStats = () => {
    return remotePeer.getStats();
  };

  const filterRelevantStats = (stats: RTCStatsReport) => {
    const relevantStats: any[] = [];
    let candidatePair: any;

    stats.forEach((entry) => {
      if (entry.type === 'transport') {
        candidatePair = stats.get(entry.selectedCandidatePairId);
      }

      if (entry.type === 'inbound-rtp') {
        relevantStats.push(entry);
      }
    });

    if (candidatePair && candidatePair.remoteCandidateId) {
      relevantStats.push(stats.get(candidatePair.remoteCandidateId));
    }

    return relevantStats;
  };

  const saveRelevantStats = (stats: any[]) => {
    const [inbountRTP, remotePeer] = stats;

    onIP(remotePeer.address);
    setDataReceived(inbountRTP.bytesReceived);
  };

  const statsPipeline = () => {
    getStats()
      .then((v) => filterRelevantStats(v))
      .then((v) => saveRelevantStats(v));
  };

  useEffect(() => {
    statsPipeline();
    const interval = window.setInterval(() => statsPipeline(), 5000);
    return () => clearInterval(interval);
  });

  const { bytes, unit } = formatBytes(dataReceived);

  return (
    <div className="stats">
      <div className="stat">
        <strong>{bytes}</strong>
        <span>{unit}</span>
      </div>
    </div>
  );
};

export default Stats;
