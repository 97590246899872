import Events from './events';

class SignalingChannel {
  static instance: SignalingChannel | null = null;

  static getInstance() {
    if (!SignalingChannel.instance) {
      SignalingChannel.instance = new SignalingChannel();
    }

    return SignalingChannel.instance;
  }

  private _socket: WebSocket | null;
  private _reconnectTimer?: number;

  constructor() {
    this._socket = null;
  }

  connect() {
    window.addEventListener('visibilitychange', (e) =>
      this._onVisibilityChange()
    );
    window.addEventListener('beforeunload', () => this._disconnect());

    clearTimeout(this._reconnectTimer);
    if (this._isConnected() || this._isConnecting()) return;
    const ws = new WebSocket(this._endpoint());
    ws.binaryType = 'arraybuffer';
    ws.onopen = (e) => console.log('WS: server connected');
    ws.onmessage = (e) => this._onMessage(e.data);
    ws.onclose = (e) => this._onDisconnect();
    ws.onerror = (e) => console.error(e);
    this._socket = ws;
  }

  _onMessage(msg: any) {
    msg = JSON.parse(msg);
    console.log(msg);

    if (msg.type === 'ping') {
      return this.send({ type: 'pong' });
    }

    if (msg.type === 'peers') {
      return Events.fire('peers', msg);
    }

    if (msg.type === 'peer-joined') {
      return Events.fire('peer-joined', msg);
    }

    if (msg.type == 'peer-left') {
      return Events.fire('peer-left', msg);
    }

    Events.fire('signal', msg);
  }

  send(message: any) {
    if (!this._isConnected()) return;
    this._socket!.send(JSON.stringify(message));
  }

  _endpoint() {
    const protocol = window.location.protocol.startsWith('https')
      ? 'wss'
      : 'ws';

    const host = window.location.protocol.startsWith('https')
      ? 'audio.purplebear.io/server'
      : 'localhost:3000';
    const url = protocol + '://' + host;
    return url;
  }

  _disconnect() {
    this.send({ type: 'disconnect' });
    this._socket!.onclose = null;
    this._socket!.close();
  }

  _onVisibilityChange() {
    if (document.hidden) return;
    this.connect();
  }

  _onDisconnect() {
    console.log('WS: server disconnected');
    clearTimeout(this._reconnectTimer);
    this._reconnectTimer = window.setTimeout(() => this.connect(), 5000);
  }

  _isConnected() {
    return this._socket && this._socket.readyState === this._socket.OPEN;
  }

  _isConnecting() {
    return this._socket && this._socket.readyState === this._socket.CONNECTING;
  }
}

export default SignalingChannel.getInstance();
