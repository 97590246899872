import Events from '../ws/events';
import Signaling from '../ws/signaling';

export default class RTCPeer {
  private serverConnection: typeof Signaling;
  public peerId: string;
  public peer!: RTCPeerConnection;

  constructor(serverConnection: typeof Signaling, id: string) {
    this.serverConnection = serverConnection;
    this.peerId = id;

    this._createPeerConnection();
  }

  async _addTracks() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      const stereoMix = devices.find(
        (d) =>
          d.kind === 'audioinput' && d.label.startsWith('Default - Stereo Mix')
      );

      if (!stereoMix) {
        return console.log('No stereo mix found.');
      }

      const stereoMixStream = await navigator.mediaDevices.getUserMedia({
        audio: {
          autoGainControl: false,
          channelCount: {
            exact: 2,
          },
          echoCancellation: false,
          latency: 0,
          noiseSuppression: false,
          sampleRate: 48000,
          sampleSize: 16,
          deviceId: {
            exact: stereoMix.deviceId,
          },
        },
        video: false,
      });

      // console.log(stereoMixStream);

      stereoMixStream.getTracks().forEach((track) => {
        this.peer.addTrack(track, stereoMixStream);
      });
    } catch (err) {
      console.error(err);
    }
  }

  _createPeerConnection() {
    this.peer = new RTCPeerConnection({
      iceServers: [],
    });

    this.peer.onicecandidate = (e) => this._onIceCandidate(e);
    this.peer.ontrack = (e) => this._onTrack(e);
  }

  _onIceCandidate({ candidate }: RTCPeerConnectionIceEvent) {
    this.serverConnection.send({
      type: 'new-ice-candidate',
      to: this.peerId,
      candidate,
    });
  }

  _onDescription(description: RTCSessionDescriptionInit) {
    console.log(description);
    this.peer.setLocalDescription(description).then((_) => {
      this.serverConnection.send({
        to: this.peerId,
        desc: this.peer.localDescription,
      });
    });
  }

  _onTrack({ track, streams }: RTCTrackEvent) {
    const sender = this.peer.getSenders();
    console.log(sender);

    // const params = sender.getParameters();

    // console.log(params);

    // if (!params.encodings) {
    //   params.encodings = [{}];
    // }

    // params.encodings[0].maxBitrate = 512 * 1000;

    // sender.setParameters(params);

    Events.fire('track', {
      track,
      streams,
      remotePeer: this.peer,
    });
  }

  _updateBandwidth(answer: RTCSessionDescriptionInit) {
    answer.sdp = answer.sdp!.replace(
      'useinbandfec=1',
      'useinbandfec=1; stereo=1; maxaveragebitrate=510000'
    );

    return answer;
  }

  onMessage(message: any) {
    if (message.type === 'new-ice-candidate') {
      return this.peer.addIceCandidate(message.candidate);
    }

    if (message.desc) {
      this.peer.setRemoteDescription(message.desc).then((_) => {
        if (message.desc.type === 'offer') {
          this._addTracks().then((_) => {
            return this.peer
              .createAnswer()
              .then((d) => this._updateBandwidth(d))
              .then((d) => this._onDescription(d));
          });
        }
      });
    }
  }

  async createOffer() {
    console.log('Created offer.');
    await this.peer
      .createOffer({
        offerToReceiveAudio: true,
      })
      .then((d) => this._updateBandwidth(d))
      .then((d) => this._onDescription(d));
  }
}
