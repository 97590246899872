import * as icons from 'react-feather';

export enum EIcon {
  HEADPHONES = 'Headphones',
  WIFI = 'Wifi',
  MONITOR = 'Monitor',
  PHONE = 'Phone',
  RADIO = 'Radio',
  VOLUME_X = 'VolumeX',
  VOLUME = 'Volume',
  VOLUME1 = 'Volume1',
  VOLUME2 = 'Volume2',
  PLAY = 'Play',
  PAUSE = 'Pause',
  CHEVRON_DOWN = 'ChevronDown',
  EYE_OFF = 'EyeOff',
}

interface IconProps {
  icon: EIcon;
  size: number;
  strokeWidth?: number;
}

const Icon: React.FC<IconProps> = ({ strokeWidth, icon, size }) => {
  const IconComponent = icons[icon];
  const width = strokeWidth || 2;
  return <IconComponent strokeWidth={width} size={size} />;
};

export default Icon;
