import Icon, { EIcon } from '../Icon';

const Header = () => {
  return (
    <header>
      <div className="header">
        <span className="header-icon">
          <Icon icon={EIcon.HEADPHONES} size={34} />
        </span>
        <span className="header-text">Audio Share</span>
      </div>
    </header>
  );
};

export default Header;
